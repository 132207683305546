import React from "react";
import { useTranslation } from "react-i18next";
import CancelIcon from "../../images/cancel.png";
import { getSessionStorage } from "../../utils/config";
import "./ResultTimeEnd.scss";

function ResultTimeEnd() {
  const { t } = useTranslation();
  const backUrl = getSessionStorage("_od")?.return_url
  return (
    <div className="result-success">
      <img src={CancelIcon} className="icon" alt="" />
      <p className="result-text">{t("transaction_cancelled")}</p>
      <p className="result-tip">
        {t("cancelled_tip1")}
      </p>
      <div className="tip-box">
        <p className="tip1">{t("result_tip1")}</p>
        <p className="tip2">
          {t("result_tip2")}{" "}
          <a href="https://www.pagsmile.com/">https://www.pagsmile.com/</a>
        </p>
      </div>
      <a className="back-btn" href={backUrl}>
        {t("back_txt")}
      </a>
    </div>
  );
}

export default ResultTimeEnd;
