import React, { forwardRef, useState } from "react";
import "./DropDownMenu.scss";
import { connect } from "react-redux";
import Chat from "../../images/客服.png";
import FaqImg from "../../images/faq.png";
import Back from "../../images/back.png";
import Instructions from "../../images/图文详情.png";
import Faq from "../faq/Faq";
import VideoContent from "../videoContent/VideoContent";
import { useTranslation } from "react-i18next";
import { methodConfig } from "../../utils/configurations";
import { titleCase, setSessionStorage } from "../../utils/config";

function DropDownMenu(props, ref) {
  //console.log("DropDownMenu-props",props)
  let methodList = [];
  let methodNameList = []
  Object.values(props.tradeInfo.form_list).map((item) => {
    item.method.forEach((m) => {
      if(methodNameList.indexOf(m.name) < 0){
        methodNameList.push(m.name)
        methodList.push({
          name: m.name,
          value: m.value,
        });
      }
    });
  });
  const [pageState, setPageState] = useState(props.state);
  const { t, i18n } = useTranslation();
  const handleLangChange = (event) => {
    setSessionStorage("lng",event.target.id)
    i18n.changeLanguage(event.target.id);
  };
  return (
    <div ref={ref} className="dropDown">
      <div className="dropDown__back">
        <img src={Back} alt="" onClick={props.back} />
      </div>
      {pageState === "main" && (
        <>
          <div className="dropDown__main">
            <div
              className="dropDown--section"
              onClick={() => {
                window?.LC_API?.open_chat_window();
              }}
            >
              <div className="dropDown--header">
                <img src={Chat} alt="" />
                <p>{t("help_chat")}</p>
              </div>
              {methodList.map((method) => {
                return (
                  <p
                    key={method.value}
                    onClick={() => {
                      window?.LC_API?.open_chat_window();
                    }}
                  >
                    {titleCase(method.name)}
                  </p>
                );
              })}
            </div>
            <div
              className="dropDown--section"
              onClick={() => {
                setPageState("inst");
              }}
            >
              <div className="dropDown--header">
                <img src={Instructions} alt="" />
                <p>{t("instructions")}</p>
              </div>
              <p>{t("videos")}</p>
              <p>{t("pictures")}</p>
              <p>{t("text")}</p>
            </div>
            <div
              className="dropDown--section"
              onClick={() => {
                setPageState("faq");
              }}
            >
              <div className="dropDown--header">
                <img src={FaqImg} alt="" />
                <p>{t("faq")}</p>
              </div>
              {props.tradeInfo?.channel_info_list?.map((item) => {
                return methodConfig()[item.method]?.faq?.map((item) => (
                  <p>{item.q}</p>
                ));
              })}
            </div>
          </div>
        </>
      )}
      {pageState === "lang" && (
        <div className="dropDown__lang">
          <p
            className="dropDown__lang__title"
            id="en"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            English
          </p>
          <p
            className="dropDown__lang__title"
            id="es"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            Español
          </p>
          <p
            className="dropDown__lang__title"
            id="pt"
            onClick={(e) => {
              handleLangChange(e);
              props.back();
            }}
          >
            Português
          </p>
        </div>
      )}

      {pageState === "faq" && <Faq />}
      {pageState === "inst" && (
        <div className="dropDown__ins">
          {props.tradeInfo?.channel_info_list?.map((item) => {
            return methodConfig()[item.method]?.video?.map((item) => (
              <VideoContent title={item.title} source={item.source} />
            ));
          })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(forwardRef(DropDownMenu));
