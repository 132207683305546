
import React from "react";
import { useTranslation } from "react-i18next";
import ProcessingIcon from "../../images/processing.png"
import { getSessionStorage } from "../../utils/config";
import "./ResultProcessing.scss"

function ResultSuccess() {
  const { t } = useTranslation();
  const backUrl = getSessionStorage("_od")?.return_url
  return (
    <div className="result-success">
      <img src={ProcessingIcon} className="icon" alt="" />
      <p className="result-text">{t("transation_processing")}</p>
      <div className="tip-box">
        <p className="tip1">{t("result_tip1")}</p>
        <p className="tip2">
          {t("result_tip2")}{" "}
          <a href="https://www.pagsmile.com/">https://www.pagsmile.com/</a>
        </p>
      </div>
      <a className="back-btn" href={backUrl}>
        {t("back_txt")}
      </a>
    </div>
  );
}

export default ResultSuccess;
