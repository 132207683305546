const privacyEn = {
  "privacy.policy": "Privacy Policy",
  "strategy.brief":
    'This policy covers how Pagsmile protects and uses the information collected about the user. Pagsmile protects the privacy of all users who visit or use the Pagsmile Service (collectively or individually, "you" or "users").',
  "policy.purpose":
    "The purpose of the Privacy Policy is to inform you about the information we collect, share and protect when you visit or use the Pagsmile Service. By using the Pagsmile Service you agree to this Privacy Policy. This Privacy Policy applies to all identifiable personal information collected on the website where this Privacy Policy is posted.",
  note: "Please note that if the user is less than 13 years old, the user must obtain the consent of one of their parents or legal guardian to access the Pagsmile Service. Pagsmile does not collect or purposely maintain identifiable information from 13-year-old people. If Pagsmile detects that identifiable personal information from 13-year-old users has been collected on our website or through our Pagsmile Service, then Pagsmile will take appropriate steps to delete this information.",
  "we.collect": "Information we collect",
  "collet.situation":
    "We will collect personal information about you in the following circumstances:",
  "open.pagsmile.account":
    "When you open a Pagsmile account, we will collect contact information including your name, address, phone number, e-mail, date of birth, and other similar information. This information may be reviewed and edited at any time when you log into your account.",
  "use.our.website":
    "When you access our web site or visit a website that uses our service: Our service gathers information automatically transmitted from the (s) device you use to access our website or the sites that integrate our service, such as your IP address, device unique identifier, browser information and system information (e.g. operating system). This information, alone or in combination with other information, may allow you to be identified.",
  "close.the.deal":
    "When you complete a transaction using our service: During the course of a transaction, it may be necessary to enter certain information about you. This information may include your full name, address, date of birth and details of the payment method you are using.",
  "third.party.use":
    "When it is provided to us by third party sources in connection with the use of our service: Our traders may send us any information you have provided, including your personal data (e.g. name, contact information) and details of past and current purchases, and current activities on their websites. In addition, we may receive information about you from the payment method provider used in our service, insofar as this information is necessary to process your transaction, or if it is necessary subsequently during an investigation into fraudulent or suspicious transactions.",
  communicate:
    "When you communicate with us: it may be necessary to provide additional information about you and your transaction in communication. In addition, we may contact you to request proof of personal identity, such as an image of a legal identification document, to ensure that your transaction is valid or to the extent that it is necessary to fulfil our legal obligations as a financial institution.",
  "how.use.your.info": "How we use your information",
  "use.your.data.reasons": "We only use your data for the following reasons:",
  "provide.pagsmile.service":
    "To offer the Pagsmile service: We will use your personal information as necessary to provide the Pagsmile service, including to the extent necessary to process the transaction you have initiated, verify your identity, authenticate your access to a Pagsmile account and communicate with you about the service.",
  "fraud.prevention":
    "To manage risks and protect you, the site and the Pagsmile service: Fraud prevention is a critical part of delivering payment services, and we use your information to help us detect and prevent fraud.",
  "fulfill.our.law":
    "To fulfill our legal obligations, including all applicable laws against money laundering, antiterrorist finance laws, financial services regulations and our contractual obligations to third party partners who provide or assist in providing any payment method used in our service",
  "other.business.needs":
    "For our other legitimate commercial needs: We may need to use your personal information to enforce our contracts and terms of service and monitor the activity on the website.",
  "how.spread.your.message":
    "How we disseminate your information outside of Pagsmile",
  "privacy.protection":
    "Your data may be disclosed to third parties in any or all of the following circumstances. As your privacy is important to us, we take steps to ensure that all the entities with whom we share your information have implemented sound privacy practices and data protection at a level comparable to what we employ",
  "launch.a.transaction":
    "When our payment partners need to process a transaction you have initiated: We have contracts with banks and other third-party financial institutions for each payment method we offer in our service. When you authorise a transaction, we will transmit to relevant third parties all the information necessary for Processing this transaction. The necessary information varies according to the type of payment, but may include your name, address and purchase details you are trying to make.",
  "transaction.processing":
    "When our traders need to process a transaction you have started: Where necessary, we will share certain information about you with our third party traders to help facilitate the transactions. Such data will never include your confidential payment details (such as credit card number).",
  "employing.third.purpose":
    "When other third parties providing services related to the Pagsmile service require it in order to provide services, such as our independent server hosting providers and auditors, we hire with the aim of analysing our compliance with the law or the relevant independent standards.",
  "law.required":
    "As required by law: We will share your information with third parties as long as we are required by law. For example, we are required by law to perform certain routine audits, which may require us to share your information with the contracted external auditors in relation to those requirements. In addition, we may have to disclose your information when we receive a valid subpoena or other request for law enforcement or when the law requires that we affirmative notify law enforcement to avoid unlawful damages or activities. The need for all such disclosures will be determined at our sole discretion. For more information about our procedures related to legal requests, see our law enforcement policy.",
  "our.business.interests":
    "insofar as our legitimate commercial interests require us to do so, for example, by transmitting it to our outsourced partners for inclusion in their black lists or closed merchant lists, to help us participate in our fraud reduction efforts, to support our corporate governance activities, or facilitate the sale or other total or partial transfer of our business, or to protect the company or our service.",
  "how.protect.your.information": "How we protect your information",
  "ssl.technology":
    "The purpose of the Pagsmile is to provide a secure and convenient on line payment experience. We always protect the security of your information using the Secure Sockets Layer (SSL) technology for each transmission that encrypts the information entered. All purchases made with credit card or alternative direct payment must use an SSL enabled browser. The use of SSL-enabled browsers best protects the confidentiality of personal information and credit card information while they are transmitted over the Internet. When you enter confidential information (such as details of the e-wallet account, cell phone number, credit card number or your password) and make an on line request, we encrypt that information using the Secure Socket Layer (SSL) technology, which is the standard sector approved.",
  "view.https":
    'Users can check the security of their internet connection by looking at the address bar of the browser window while ordering on line. If you see a non-broken key or a closed lock, the SSL will be active and the server will be safe. You can also be sure by looking at the URL of your browser. When accessing a secure server, the first characters from the site address will be changed from "http" to "https". For credit card transactions, we will securely transmit the entire credit card number to be processed by the credit card company. We will only disclose the last four digits of your credit card number when printing a request confirmation. For security reasons, we do not maintain or store your credit card information, as it is used only to complete the safe transaction. Your credit card number and other confidential information will not be stored after completion of the transaction to help protect credit card information. To protect you further from unauthorized access to your account and computer, make sure you delete it when you finish.',
  "your.information.choices": "Your choices about your information",
  "you.have.right": "You have the right to:",
  "modify.your.message":
    "Request that we modify any personal information that belongs to you.",
  "delete.your.information":
    "Request that we delete any personal information regarding you.",
  "contact.us.delete.info":
    "To request a change in your personal information or delete your personal information, please contact us.",
  "pagsmile.user.information": "Pagsmile User Information",
  "not.provide.your.private_info":
    "Pagsmile will not request at any time the complete information on your credit card, the account ID and the password or the national identification numbers in a non-secure or unsolicited email or by telephone communication. Never divulge your passwords for any reason.",
  cookies: "Cookies",
  "cookies.introduction":
    "Cookies are text files placed on your computer to collect standard Internet registration information and behavior information from visitors. When you visit our websites, we may collect information automatically through cookies or similar technology",
  "third.cookies": "Cookies may be cookies from third parties or third parties",
  "storage.cookies":
    "Primary cookies that the site you are visiting stores on your computer.",
  "from.third.cookies":
    "Cookies from third parties --cookies stored on your computer via the website, but by third parties, such as Google.",
  "user.Following.cookies": "We use the following cookies on our website:",
  "strictly.session": "Cookies strictly required/ session",
  "cookies.effect":
    "These cookies are essential to allow you to navigate through our website and use your resources. Without these cookies, the requested services cannot be provided. They are excluded when you close the browser. These are primary cookies.",
  "cookies.performance": "Performance cookies",
  "cookies.specific.purpose":
    "These cookies collect anonymous data on how visitors use our website. They allow us to recognize and count the number of visitors and se e how visitors move through the site when they use it and the approximate regions of where they are visiting. These are primary cookies.",
  "functional.cookies": "Functional Cookies",
  "cookie.recorded.information":
    "These cookies allow the site to remember the choices you make (such as user name, language or region you are in) and provide improved and more personal resources. These cookies can also be used to remember changes made to text size, fonts and other parts of web pages that you can customize. The information that these cookies collect can be anonymized and cannot trace your browsing activity on other sites. These are primary cookies.",
  "analytical.cookies": "Analytical cookies, advertising and social media",
  "cookies.benefit":
    "The use of cookies allows us and our advertisers to provide more relevant information to you and your interests and they can also connect you to social media networks. These are persistent cookies that will be kept on your device until its expiration or previous manual deletion.",
  "consent2exclusion.cookies": "Consent and exclusion of cookies",
  "agree.cookies":
    "When you arrive at our website, a pop-up message will appear by notifying that our website uses cookies. By using our website, you agree to the use of cookies. If you or another user of your device wishes to withdraw your consent at any time, you can do so by changing the browser settings, otherwise we will assume that you are happy to receive cookies from our website.",
  contact: "Contact.",
  "have.questions.ask.me":
    "If you have any questions about this Privacy Policy, please contact us.",
  "change.privacy.policy": "Changes to this Privacy Policy",
  "change.privacy.policy.anytime":
    'We can change the Privacy Policy from time to time. We will inform you by publishing the revised Privacy Policy on the Site. Check our Privacy Policy periodically for changes. We will post the date of the latest update of the Privacy Policy at the bottom of the Privacy Policy. Changes will enter into force on the date "Last Update" shown in the revised Privacy Policy. By continuing to use the site, you agree to the revised Privacy Policy.',
  "last.updated": "Last updated: 26 December of 2018",
};

const privacyPt = {
  "privacy.policy": "Política de Privacidade",
  "strategy.brief":
    'Esta política cobre como a Pagsmile ("nós”) protege e utiliza as informações coletadas sobre o usuário. A Pagsmile protege a privacidade de todos os usuários que visitam ou utilizam o Serviço Pagsmile (coletivamente ou individualmente, "você”ou "usuários”).',
  "policy.purpose":
    "O propósito da Política de Privacidade é de informá-lo sobre as informações que coletamos, compartilhamos e protegemos quando você visita ou utiliza o Serviço Pagsmile. Ao utilizar o Serviço Pagsmile você concorda com esta Política de Privacidade. Esta Política de Privacidade se aplica a todas as informações pessoais identificáveis coletadas no website onde esta Política de Privacidade está postada.",
  note: "Por favor note que se o usuário for menor de 13 anos, o usuário deve obter o consentimento de um de seus pais ou responsável legal para poder acessar o Serviço Pagsmile. A Pagsmile não coleta ou mantém propositalmente informações identificáveis de pessoas menores de 13 anos de idade. Se a Pagsmile detectar que informações pessoais identificáveis de usuários menores de 13 anos de idade foram coletadas no nosso website ou através de nosso Serviço Pagsmile, então a Pagsmile tomará as devidas providências para deletar essas informações.",
  "deduction.description":
    "Ao aceitar este contrato, você nos autoriza a reter, receber e desembolsar fundos em seu nome quando esses fundos de suas transações de pagamento são liquidados de / para os parceiros de pagamento locais e internacionais. Você também autoriza o Pagsmile a reter fundos de liquidação em uma conta de depósito, aguardando o desembolso dos fundos para você de acordo com os termos deste contrato. Você concorda que não tem direito a nenhum interesse ou outra compensação associada aos fundos de liquidação mantidos na conta de depósito pendente de liquidação na sua conta de depósito designada, que não tem o direito de direcionar essa conta de depósito e que não pode atribuir nenhum interesse na conta de depósito. Exibiremos o acordo antecipado no painel do administrador que temos ou estamos definidos para receber em seu nome. Essas informações de liquidação não constituem um depósito ou outra obrigação da Pagsmile para você. Essas informações de liquidação refletidas no painel de gerenciamento do Pagsmile são apenas para fins de relatório e informações, e você não tem direito e não possui propriedade ou outros direitos sobre os fundos de liquidação, até que esses fundos sejam creditados na sua conta de depósito designada. Suas autorizações estabelecidas neste documento permanecerão em pleno vigor e efeito até que sua conta Pagsmile seja fechada ou encerrada.",
  "we.collect": "Informações que nós coletamos",
  "collet.situation":
    "Recolheremos informações pessoais sobre você nas seguintes circunstâncias:",
  "open.pagsmile.account":
    "Quando você abrir uma conta Pagsmile, nós coletaremos as informações de contato incluindo – seu nome, endereço, número de telefone, e-mail, data de nascimento, e outras informações similares. Estas informações podem ser revistas e editadas em qualquer momento ao logar em sua conta.",
  "use.our.website":
    "Quando você acessar nosso website ou visitar uma página web que usa o nosso serviço: Nosso serviço reúne informações transmitidas automaticamente do (s) dispositivo (s) que você usa para acessar nosso site ou dos sites que integram nosso serviço, como seu endereço IP, identificador exclusivo de dispositivo, informações do navegador e informações do sistema (por exemplo, sistema operacional). Essas informações, sozinhas ou combinadas com outras informações, podem permitir que você seja identificado.",
  "close.the.deal":
    "Quando você conclui uma transação usando nosso serviço: Durante o curso de uma transação, pode ser necessário inserir determinadas informações sobre você. Essas informações podem incluir seu nome completo, endereço, data de nascimento e detalhes sobre o método de pagamento que você está usando.",
  "third.party.use":
    "Quando nos é fornecido por fontes de terceiros em conexão com o uso do nosso serviço: Nossos comerciantes podem nos enviar qualquer informação que você tenha fornecido, incluindo seus dados pessoais (por exemplo, nome, informações de contato) e detalhes sobre compras passadas e atuais, e atividades atuais em seus sites. Além disso, podemos receber informações sobre você do provedor do método de pagamento usado em nosso serviço, na medida em que essas informações sejam necessárias para processar sua transação, ou se forem necessárias posteriormente durante uma investigação sobre transações fraudulentas ou suspeitas.",
  communicate:
    "Quando você se comunica conosco: pode ser necessário fornecer informações adicionais sobre você e sua transação na comunicação. Além disso, podemos entrar em contato com você para solicitar prova de identidade pessoal, como uma imagem de um documento de identificação legal, para garantir que sua transação seja válida ou na medida em que seja necessário cumprir as nossas obrigações legais como instituição financeira.",
  "how.use.your.info": "Como utilizamos sua informação",
  "use.your.data.reasons":
    "Somente utilizamos os seus dados pelos seguinte motivos:",
  "provide.pagsmile.service":
    "Para oferecer o serviço Pagsmile: Usaremos suas informações pessoais conforme necessário para fornecer o serviço Pagsmile, inclusive na medida necessária para processar a transação que você iniciou, verificar sua identidade, autenticar seu acesso a uma conta Pagsmile e comunicar-se com você sobre o serviço.",
  "fraud.prevention":
    "Para gerenciar riscos e proteger você, o site e o serviço Pagsmile: A prevenção de fraudes é uma parte crítica da prestação de serviços de pagamento, e usamos suas informações para nos ajudar a detectar e prevenir fraudes.",
  "fulfill.our.law":
    "Para cumprir nossas obrigações legais, incluindo todas as leis aplicáveis de combate à lavagem de dinheiro, leis de financiamento antiterrorista, regulamentos de serviços financeiros e nossas obrigações contratuais com os parceiros terceirizados que fornecem ou ajudam a fornecer qualquer método de pagamento usado em nosso serviço",
  "other.business.needs":
    "Para nossas outras necessidades comerciais legítimas: Podemos precisar usar suas informações pessoais para fazer cumprir nossos contratos e termos de serviço e monitorar a atividade no site.",
  "how.spread.your.message":
    "Como divulgamos suas informações fora da Pagsmile",
  "privacy.protection":
    "Seus dados podem ser divulgados a terceiros em qualquer uma ou todas as seguintes circunstâncias. Como sua privacidade é importante para nós, tomamos medidas para garantir que todas as entidades com as quais compartilhamos suas informações tenham implementado práticas sólidas de privacidade e proteção de dados em um nível comparável ao que empregamos",
  "launch.a.transaction":
    "Quando nossos parceiros de pagamento precisam processar uma transação que você iniciou: Temos contratos com bancos e outras instituições financeiras de terceiros para cada método de pagamento que oferecemos em nosso serviço. Quando você autoriza uma transação, transmitiremos a terceiros relevantes todas as informações necessárias para processar essa transação. As informações necessárias variam de acordo com o tipo de pagamento, mas podem incluir seu nome, endereço e detalhes da compra que você está tentando fazer.",
  "transaction.processing":
    "Quando nossos comerciantes precisarem processar uma transação que você iniciou: Onde necessário, compartilharemos certas informações sobre você com nossos comerciantes terceiros para ajudar a facilitar as transações. Esses dados nunca incluirão seus detalhes de pagamento confidenciais (como número do cartão de crédito).",
  "employing.third.purpose":
    "Quando outros terceiros que nos prestam serviços relacionados ao serviço Pagsmile o exigem, a fim de prestar os serviços, como nossos provedores de hospedagem de servidores e auditores independentes, contratamos com o objetivo de analisar nossa conformidade com a lei ou com os padrões independentes relevantes.",
  "law.required":
    "Na medida exigida por lei: Compartilharemos suas informações com terceiros na medida em que formos obrigados por lei. Por exemplo, somos obrigados por lei a realizar certas auditorias de rotina, o que pode exigir que compartilhemos suas informações com os auditores externos contratados em relação a esses requisitos. Além disso, podemos ter que divulgar suas informações quando recebermos uma intimação válida ou outra solicitação de aplicação da lei ou quando a lei exigir que notifiquemos afirmativamente a aplicação da lei para evitar danos ou atividades ilegais. A necessidade de todas essas divulgações será determinada a nosso critério exclusivo. Para obter mais informações sobre nossos procedimentos relacionados a solicitações legais, consulte nossa política de aplicação da lei.",
  "our.business.interests":
    "Na medida em que nossos interesses comerciais legítimos exigem que o façamos, por exemplo, transmitindo-o a nossos parceiros terceirizados para inclusão em suas listas negras ou listas de comerciantes encerrados, para nos ajudar a participar de nossos esforços de redução de fraudes, para apoiar nossas atividades de governança corporativa ou facilitar a venda ou outra transferência total ou parcial de nossos negócios, ou para proteger a empresa ou nosso serviço.",
  "how.protect.your.information": "Como protegemos a sua informação",
  "ssl.technology":
    "O objetivo do Pagsmile é fornecer uma experiência de pagamento on-line segura e conveniente. Sempre protegemos a segurança de suas informações usando a tecnologia Secure Sockets Layer (SSL) para cada transmissão que criptografa as informações inseridas. Todas as compras feitas com cartão de crédito ou pagamento direto alternativo devem usar um navegador habilitado para SSL. O uso de navegadores habilitados para SSL protege melhor a confidencialidade das informações pessoais e do cartão de crédito enquanto elas são transmitidas pela Internet. Quando você insere informações confidenciais (como detalhes da conta da carteira eletrônica, número de celular, número do cartão de crédito ou sua senha) e faz um pedido on-line, criptografamos essas informações usando a tecnologia Secure Socket Layer (SSL), que é o padrão do setor aprovado.",
  "view.https":
    'Os usuários podem verificar a segurança de sua conexão à Internet olhando a barra de endereço da janela do navegador enquanto faz seu pedido on-line. Se você ver uma chave não quebrada ou um cadeado fechado, o SSL estará ativo e o servidor estará seguro. Você também pode se certificar olhando para a URL do seu navegador. Ao acessar um servidor seguro, os primeiros caracteres do endereço do site serão alterados de "http" para "https". Para transações com cartão de crédito, transmitiremos com segurança todo o número do cartão de crédito a ser processado pela empresa do cartão de crédito. Apenas revelaremos os últimos quatro dígitos do número do seu cartão de crédito ao imprimir uma confirmação de pedido. Por motivos de segurança, não mantemos ou armazenamos as informações do seu cartão de crédito, pois são usadas apenas para concluir a transação segura. O número do seu cartão de crédito e outras informações confidenciais não serão armazenadas após a conclusão da transação para ajudar a proteger as informações do cartão de crédito. Para proteger ainda mais contra o acesso não autorizado à sua conta e ao seu computador, certifique-se de deslogar quando terminar.',
  "your.information.choices": "Suas escolhas sobre a sua informação",
  "you.have.right": "Você tem o direito de:",
  "modify.your.message":
    "Solicitar que alteremos quaisquer informações pessoais que pertencem a você.",
  "delete.your.information":
    "Solicitar que excluamos qualquer informação pessoal referente a você.",
  "contact.us.delete.info":
    "Para solicitar uma alteração em suas informações pessoais ou excluir suas informações pessoais, entre em contato conosco.",
  "pagsmile.user.information": "Informação do usuário Pagsmile",
  "not.provide.your.private_info":
    "A Pagsmile não solicitará, em qualquer momento, as informações completas do seu cartão de crédito, o ID da conta e a senha ou os números de identificação nacional em um email não seguro ou não solicitado ou por meio de comunicação telefônica. Nunca divulgue suas senhas por qualquer motivo.",
  cookies: "Cookies",
  "cookies.introduction":
    "Cookies são arquivos de texto colocados no seu computador para coletar informações padrão de registro na Internet e informações de comportamento dos visitantes. Quando você visita nossos sites, podemos coletar informações automaticamente através de cookies ou tecnologia similar",
  "third.cookies": "Os cookies podem ser cookies de terceiros ou de terceiros",
  "storage.cookies":
    "Cookies primários - cookies que o site que você está visitando armazena no seu computador.",
  "from.third.cookies":
    "Cookies de terceiros - cookies armazenados no seu computador através do site, mas por terceiros, como o Google.",
  "user.Following.cookies": "Utilizamos os seguintes cookies no nosso site:",
  "strictly.session": "Cookies estritamente necessários / sessão",
  "cookies.effect":
    "Esses cookies são essenciais para permitir que você navegue pelo nosso site e use seus recursos. Sem esses cookies, os serviços solicitados não podem ser fornecidos. Eles são excluídos quando você fecha o navegador. Estes são cookies primários.",
  "cookies.performance": "Cookies de desempenho",
  "cookies.specific.purpose":
    "Esses cookies coletam dados anônimos sobre como os visitantes usam nosso site. Eles nos permitem reconhecer e contar o número de visitantes e ver como os visitantes se movem pelo site quando o usam e as regiões aproximadas de onde estão visitando. Estes são cookies primários.",
  "functional.cookies": "Cookies de funcionalidade",
  "cookie.recorded.information":
    "Esses cookies permitem que o site se lembre das escolhas que você faz (como nome de usuário, idioma ou região em que se encontra) e forneça recursos aprimorados e mais pessoais. Esses cookies também podem ser usados para lembrar as alterações feitas no tamanho do texto, fontes e outras partes das páginas da Web que você pode personalizar. As informações que esses cookies coletam podem ser anonimizadas e não podem rastrear sua atividade de navegação em outros sites. Estes são cookies primários.",
  "analytical.cookies": "Cookies de análise, publicidade e mídias sociais",
  "cookies.benefit":
    "O uso de cookies permite que nós e nossos anunciantes forneçamos informações mais relevantes para você e seus interesses e eles também podem conectá-lo às redes de mídia social. Estes são cookies persistentes que serão mantidos no seu dispositivo até sua expiração ou exclusão manual anterior.",
  "consent2exclusion.cookies": "Consentimento e exclusão de cookies",
  "agree.cookies":
    "Quando você chegar ao nosso site, uma mensagem pop-up aparecerá notificando que nosso site usa cookies. Ao usar nosso site, você concorda com o uso de cookies. Se você ou outro usuário do seu dispositivo desejar retirar seu consentimento a qualquer momento, poderá fazê-lo alterando as configurações do navegador, caso contrário, assumiremos que você está feliz em receber cookies do nosso site.",
  contact: "Contate-nos",
  "have.questions.ask.me":
    "Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco.",
  "change.privacy.policy": "Alterações nesta Política de Privacidade",
  "change.privacy.policy.anytime":
    'Podemos alterar a Política de Privacidade de tempos em tempos. Informaremos você publicando a Política de Privacidade revisada no Site. Verifique nossa Política de Privacidade periodicamente para alterações. Publicaremos a data da última atualização da Política de Privacidade na parte inferior da Política de Privacidade. As alterações entrarão em vigor na data "Última atualização" mostrada na Política de Privacidade revisada. Ao continuar a usar o site, você concorda com a Política de Privacidade revisada.',
  "last.updated": "Última atualização: 26 de dezembro de 2018",
};

const privacyEs = {
  "privacy.policy": "Política de Privacidade",
  "strategy.brief":
    'Esta política cobre como o Pagsmile protege e usa as informações coletadas sobre o usuário. Pagsmile protege a privacidade de todos os usuários que visitam ou usam o Serviço Pagsmile (coletiva ou individualmente, "você" ou "usuários").',
  "policy.purpose":
    "O objetivo da Política de Privacidade é informá-lo sobre as informações que coletamos, compartilhamos e protegemos quando você visita ou usa o Serviço Pagsmile. Ao usar o Serviço Pagsmile, você concorda com esta Política de Privacidade. Esta Política de Privacidade se aplica a todas as informações pessoais identificáveis ​​coletadas no site onde esta Política de Privacidade é publicada.",
  note: "Observe que se o usuário tiver menos de 13 anos de idade, o usuário deve obter o consentimento de um dos pais ou responsável legal para acessar o Serviço Pagsmile. O Pagsmile não coleta ou mantém intencionalmente informações identificáveis ​​de pessoas com 13 anos de idade. Se Pagsmile detectar que informações pessoais identificáveis ​​de usuários de 13 anos de idade foram coletadas em nosso site ou através de nosso Serviço Pagsmile, então Pagsmile tomará as medidas adequadas para excluir essas informações.",
  "we.collect": "Informações que coletamos",
  "collet.situation":
    "Coletaremos informações pessoais sobre você nas seguintes circunstâncias:",
  "open.pagsmile.account":
    "Quando você abre uma conta no Pagsmile, coletamos informações de contato, incluindo seu nome, endereço, número de telefone, e-mail, data de nascimento e outras informações semelhantes. Essas informações podem ser revisadas e editadas a qualquer momento quando você entrar em sua conta.",
  "use.our.website":
    "Quando você acessa nosso site ou visita um site que usa nosso serviço: Nosso serviço coleta informações transmitidas automaticamente do (s) dispositivo (s) que você usa para acessar nosso site ou sites que integram nosso serviço, como seu endereço IP, identificador exclusivo do dispositivo , informações do navegador e informações do sistema (por exemplo, sistema operacional). Essas informações, por si só ou em combinação com outras informações, podem permitir que você seja identificado.",
  "close.the.deal":
    "Quando você conclui uma transação usando nosso serviço: Durante o curso de uma transação, pode ser necessário inserir algumas informações sobre você. Essas informações podem incluir seu nome completo, endereço, data de nascimento e detalhes da forma de pagamento que você está usando.",
  "third.party.use":
    "Quando nos é fornecido por fontes de terceiros em conexão com o uso de nosso serviço: Nossos comerciantes podem nos enviar qualquer informação que você forneceu, incluindo seus dados pessoais (por exemplo, nome, informações de contato) e detalhes de compras anteriores e atuais, e atividades atuais em seus sites. Além disso, podemos receber informações sobre você do provedor do método de pagamento usado em nosso serviço, na medida em que essas informações sejam necessárias para processar sua transação, ou se for necessário posteriormente durante uma investigação de transações fraudulentas ou suspeitas.",
  communicate:
    "Quando você se comunica conosco: pode ser necessário fornecer informações adicionais sobre você e sua transação na comunicação. Além disso, podemos entrar em contato com você para solicitar prova de identidade pessoal, como a imagem de um documento de identificação legal, para garantir que sua transação seja válida ou na medida em que seja necessário para cumprir nossas obrigações legais como instituição financeira.",
  "how.use.your.info": "Como usamos suas informações",
  "use.your.data.reasons": "Usamos seus dados apenas pelos seguintes motivos:",
  "provide.pagsmile.service":
    "Para oferecer o serviço Pagsmile: Usaremos suas informações pessoais conforme necessário para fornecer o serviço Pagsmile, incluindo na medida necessária para processar a transação que você iniciou, verificar sua identidade, autenticar seu acesso a uma conta Pagsmile e comunicar-se com você sobre o serviço.",
  "fraud.prevention":
    "Para gerenciar riscos e proteger você, o site e o serviço Pagsmile: A prevenção de fraudes é uma parte crítica da entrega de serviços de pagamento e usamos suas informações para nos ajudar a detectar e prevenir fraudes.",
  "fulfill.our.law":
    "Para cumprir nossas obrigações legais, incluindo todas as leis aplicáveis ​​contra lavagem de dinheiro, leis financeiras antiterroristas, regulamentos de serviços financeiros e nossas obrigações contratuais com parceiros terceiros que fornecem ou auxiliam no fornecimento de qualquer método de pagamento usado em nosso serviço",
  "other.business.needs":
    "Para nossas outras necessidades comerciais legítimas: Podemos precisar usar suas informações pessoais para fazer cumprir nossos contratos e termos de serviço e monitorar a atividade no site.",
  "how.spread.your.message":
    "Como disseminamos suas informações fora do Pagsmile",
  "privacy.protection":
    "Seus dados podem ser divulgados a terceiros em qualquer uma ou todas as seguintes circunstâncias. Como sua privacidade é importante para nós, tomamos medidas para garantir que todas as entidades com as quais compartilhamos suas informações implementem práticas sólidas de privacidade e proteção de dados em um nível comparável ao que empregamos",
  "launch.a.transaction":
    "Quando nossos parceiros de pagamento precisam processar uma transação que você iniciou: Temos contratos com bancos e outras instituições financeiras terceirizadas para cada método de pagamento que oferecemos em nosso serviço. Quando você autorizar uma transação, transmitiremos a terceiros relevantes todas as informações necessárias para o processamento dessa transação. As informações necessárias variam de acordo com o tipo de pagamento, mas podem incluir seu nome, endereço e detalhes de compra que você está tentando fazer.",
  "transaction.processing":
    "Quando nossos negociantes precisam processar uma transação, você iniciou: Quando necessário, compartilharemos certas informações sobre você com nossos negociantes terceirizados para ajudar a facilitar as transações. Esses dados nunca incluirão seus detalhes confidenciais de pagamento (como número de cartão de crédito).",
  "employing.third.purpose":
    "Quando terceiros prestando serviços relacionados ao serviço Pagsmile o exigirem para a prestação de serviços, tais como nossos provedores de hospedagem de servidores independentes e auditores, contratamos com o objetivo de analisar nossa conformidade com a lei ou com as normas independentes relevantes.",
  "law.required":
    "Conforme exigido por lei: Compartilharemos suas informações com terceiros enquanto for exigido por lei. Por exemplo, somos obrigados por lei a realizar certas auditorias de rotina, o que pode exigir que compartilhemos suas informações com os auditores externos contratados em relação a esses requisitos. Além disso, podemos ter que divulgar suas informações quando recebermos uma intimação válida ou outro pedido de aplicação da lei ou quando a lei exigir que notifiquemos as autoridades legais para evitar danos ou atividades ilegais. A necessidade de todas essas divulgações será determinada a nosso exclusivo critério. Para obter mais informações sobre nossos procedimentos relacionados a solicitações legais, consulte nossa política de aplicação da lei.",
  "our.business.interests":
    "na medida em que nossos interesses comerciais legítimos exigem que o façamos, por exemplo, transmitindo-o aos nossos parceiros terceirizados para inclusão em suas listas negras ou listas de comerciantes fechadas, para nos ajudar a participar em nossos esforços de redução de fraude, para apoiar nossas atividades de governança corporativa, ou facilitar a venda ou outra transferência total ou parcial de nosso negócio, ou para proteger a empresa ou nosso serviço.",
  "how.protect.your.information": "Como protegemos suas informações",
  "ssl.technology":
    "O objetivo do Pagsmile é fornecer uma experiência de pagamento online segura e conveniente. Sempre protegemos a segurança de suas informações usando a tecnologia Secure Sockets Layer (SSL) para cada transmissão que criptografa as informações inseridas. Todas as compras feitas com cartão de crédito ou pagamento direto alternativo devem usar um navegador habilitado para SSL. O uso de navegadores habilitados para SSL protege melhor a confidencialidade de informações pessoais e informações de cartão de crédito enquanto são transmitidas pela Internet. Quando você insere informações confidenciais (como detalhes da conta da carteira eletrônica, número de telefone celular, número do cartão de crédito ou sua senha) e faz uma solicitação online, criptografamos essas informações usando a tecnologia Secure Socket Layer (SSL), que é o setor padrão aprovado.",
  "view.https":
    'Os usuários podem verificar a segurança de sua conexão com a Internet olhando a barra de endereços da janela do navegador enquanto fazem pedidos online. Se você vir uma chave não quebrada ou um cadeado fechado, o SSL estará ativo e o servidor estará seguro. Você também pode ter certeza olhando para o URL do seu navegador. Ao acessar um servidor seguro, os primeiros caracteres do endereço do site serão alterados de "http" para "https". Para transações com cartão de crédito, transmitiremos com segurança todo o número do cartão de crédito para ser processado pela administradora do cartão de crédito. Apenas divulgaremos os últimos quatro dígitos do número do seu cartão de crédito ao imprimir uma confirmação de solicitação. Por motivos de segurança, não mantemos ou armazenamos as informações do seu cartão de crédito, pois elas são usadas apenas para concluir a transação segura. O número do seu cartão de crédito e outras informações confidenciais não serão armazenadas após a conclusão da transação para ajudar a proteger as informações do cartão de crédito. Para protegê-lo ainda mais de acesso não autorizado à sua conta e computador, certifique-se de excluí-lo quando terminar.',
  "your.information.choices": "Suas escolhas sobre suas informações",
  "you.have.right": "Você tem o direito de:",
  "modify.your.message":
    "Solicite que modifiquemos quaisquer informações pessoais que pertençam a você.",
  "delete.your.information":
    "Solicite que excluamos qualquer informação pessoal a seu respeito.",
  "contact.us.delete.info":
    "Para solicitar uma alteração em suas informações pessoais ou excluir suas informações pessoais, entre em contato conosco.",
  "pagsmile.user.information": "Informações do usuário do Pagsmile",
  "not.provide.your.private_info":
    "A Pagsmile não solicitará a qualquer momento as informações completas sobre o seu cartão de crédito, o ID da conta e a senha ou os números de identificação nacional em um e-mail não seguro ou não solicitado ou por comunicação telefônica. Nunca divulgue suas senhas por qualquer motivo.",
  cookies: "Biscoitos",
  "cookies.introduction":
    "Cookies são arquivos de texto colocados em seu computador para coletar informações de registro padrão da Internet e informações de comportamento dos visitantes. Quando você visita nossos sites, podemos coletar informações automaticamente por meio de cookies ou tecnologia semelhante",
  "third.cookies": "Os cookies podem ser cookies de terceiros ou terceiros",
  "storage.cookies":
    "Cookies primários que o site que você está visitando armazena em seu computador.",
  "from.third.cookies":
    "Cookies de terceiros --cookies armazenados no seu computador através do site, mas por terceiros, como o Google.",
  "user.Following.cookies": "Usamos os seguintes cookies em nosso site:",
  "strictly.session": "Cookies estritamente necessários / sessão",
  "cookies.effect":
    "Esses cookies são essenciais para permitir que você navegue em nosso site e use seus recursos. Sem esses cookies, os serviços solicitados não podem ser fornecidos. Eles são excluídos quando você fecha o navegador. Esses são cookies primários.",
  "cookies.performance": "Cookies de desempenho",
  "cookies.specific.purpose":
    "Esses cookies coletam dados anônimos sobre como os visitantes usam nosso site. Eles nos permitem reconhecer e contar o número de visitantes e ver como os visitantes se movem pelo site quando o utilizam e as regiões aproximadas de onde estão visitando. Esses são cookies primários.",
  "functional.cookies": "Cookies Funcionais",
  "cookie.recorded.information":
    "Esses cookies permitem que o site se lembre das escolhas que você faz (como nome de usuário, idioma ou região em que você está) e fornece recursos aprimorados e mais pessoais. Esses cookies também podem ser usados ​​para lembrar as alterações feitas no tamanho do texto, fontes e outras partes das páginas da web que você pode personalizar. As informações que esses cookies coletam podem ser anônimas e não podem rastrear sua atividade de navegação em outros sites. Esses são cookies primários.",
  "analytical.cookies": "Cookies analíticos, publicidade e mídia social",
  "cookies.benefit":
    "O uso de cookies permite que nós e nossos anunciantes forneçamos informações mais relevantes para você e seus interesses e eles também podem conectá-lo a redes de mídia social. Esses são cookies persistentes que serão mantidos em seu dispositivo até sua expiração ou exclusão manual anterior.",
  "consent2exclusion.cookies": "Consentimento e exclusão de cookies",
  "agree.cookies":
    "Quando você chega em nosso site, uma mensagem pop-up aparecerá notificando que nosso site usa cookies. Ao utilizar nosso site, você concorda com o uso de cookies. Se você ou outro usuário do seu dispositivo desejar retirar o seu consentimento a qualquer momento, você pode fazê-lo alterando as configurações do navegador, caso contrário, presumiremos que você concorda em receber cookies do nosso site.",
  contact: "Contato.",
  "have.questions.ask.me":
    "Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco.",
  "change.privacy.policy": "Mudanças nesta Política de Privacidade",
  "change.privacy.policy.anytime":
    'Podemos alterar a Política de Privacidade de tempos em tempos. Iremos informá-lo publicando a Política de Privacidade revisada no Site. Verifique nossa Política de Privacidade periodicamente para alterações. Publicaremos a data da última atualização da Política de Privacidade na parte inferior da Política de Privacidade. As alterações entrarão em vigor na data "Última atualização" mostrada na Política de Privacidade revisada. Ao continuar a usar o site, você concorda com a Política de Privacidade revisada.',
  "last.updated": "Última atualização: 26 de dezembro de 2018",
};

export { privacyEn, privacyPt, privacyEs };
