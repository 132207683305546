import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { notify, getSessionStorage, merchantImgMap } from "../../utils/config";
import { useTranslation } from "react-i18next";
import { setPrivacyPolicy } from "../../redux/actions";

import Checkbox from "@material-ui/core/Checkbox";
import TimeIcon from "../../images/time_small.png";

import GoBackImg from "../../images/goback.png";
import pagsmileLogo from "../../images/pagsmile_logo.png";
import TimeRemaining from "../../package/timeRemaining/TimeRemaining";

import "./TradeForm.scss";
const classNames = require("classnames");

function TradeForm(props) {
  //console.log("TradeForm-props", props);
  const { t } = useTranslation();
  const propsTradeInfo = props.tradeInfo;
  let propsOrderDetail = propsTradeInfo.order_detail;
  let propsTradeFormDetail = props.tradeFormDetail;

  if (!props.isHomePage) {
    propsOrderDetail = getSessionStorage("_od") || {};
    propsTradeFormDetail = getSessionStorage("_td") || {};
  }

  const [isDiabled, setIsDisabled] = useState(false);
  const disablePay = () => {
    setIsDisabled(true);
  };

  const [isChecked, setIsChecked] = useState(true);

  const handleFormConfirm = () => {
    if (isDiabled) {
      notify("error", "Invalid Token!");
      return false;
    }
    if (!props.step1) {
      props.setNoticeText(t("home.step.first"));
      props.setSelectNotice(true);
    } else if (!props.step2) {
      props.setNoticeText(t("home.step.second"));
      props.setSelectNotice(true);
    }
  };

  const handleChangePrivacy = (e) => {
    setIsChecked(e.target.checked);
    props.setPrivacyPolicy(e.target.checked);
  };

  const handleShowPolicy = () => {
    props.handleSetShowPolicy(true);
  };

  return (
    <div className="payout-form">
      {props.isHomePage && (
        <TimeRemaining
          image={TimeIcon}
          time={propsTradeInfo.order_detail?.remain_time}
          disable={disablePay}
        />
      )}
      <div className="trade-detail-wrap">
        <div className="payer">
          <h3 className="trade-tip">{t("payer")}</h3>
          <div className="payer-img-box">
            {merchantImgMap[propsOrderDetail.id] ? (
              <img src={merchantImgMap[propsOrderDetail.id]} alt="" />
            ) : (
              <img src={pagsmileLogo} alt="" />
            )}
          </div>
          <h3 className="trade-tip">{t("general")}</h3>
          <div className="trade-detail-item">
            <label>{t("transaction_id")}</label>
            <span
              style={{
                maxWidth: "190px",
                wordBreak: "break-all",
                lineHeight: "18px",
              }}
            >
              {propsOrderDetail.custom_code}
            </span>
          </div>
          <div className="trade-detail-item">
            <label>{t("description")}</label>
            <span
              style={{
                maxWidth: "230px",
                wordBreak: "break-all",
                lineHeight: "18px",
              }}
            >
              {propsOrderDetail.description}
            </span>
          </div>
          <hr className="baseline" />
          <h3 className="trade-tip">{t("currency")}</h3>
          <div className="trade-detail-item">
            <label>{t("from")}</label>
            <span>
              {propsOrderDetail?.payout_currency || "--"}{" "}
              {propsOrderDetail?.payout_currency === "BRL" ? (
                <span>
                  {(propsOrderDetail?.payout_amount)
                    .toString()
                    ?.replace(".", ",")}
                </span>
              ) : (
                <span>{propsOrderDetail?.payout_amount || "--"}</span>
              )}
            </span>
          </div>
          <div className="trade-detail-item">
            <label>{t("exchange_rate")}</label>
            <span>{propsTradeFormDetail?.exchange_rate || "--"}</span>
          </div>
          <div className="trade-detail-item">
            <label>{t("to")}</label>
            <span>
              {propsTradeFormDetail?.arrival_currency}{" "}
              {propsTradeFormDetail?.arrival_currency === "BRL" ? (
                <span>
                  {(propsTradeFormDetail?.arrival_amount)
                    .toString()
                    .replace(".", ",") || "--"}
                </span>
              ) : (
                <span>{propsTradeFormDetail?.arrival_amount || "--"}</span>
              )}
            </span>
          </div>
          <div className="trade-detail-item">
            <label>{t("taxes")}</label>
            <span>
              {propsTradeFormDetail?.tax * 1 === 0
                ? "--"
                : propsTradeFormDetail?.arrival_currency}{" "}
              {propsTradeFormDetail?.arrival_currency === "BRL" ? (
                <span>
                  {propsTradeFormDetail?.tax * 1 === 0
                    ? ""
                    : (propsTradeFormDetail?.tax)
                        .toString()
                        .replace(".", ",") || "--"}
                </span>
              ) : (
                <span>
                  {propsTradeFormDetail?.tax * 1 === 0
                    ? ""
                    : propsTradeFormDetail?.tax || "--"}
                </span>
              )}
            </span>
          </div>
          <div className="trade-detail-item">
            <label>{t("bank_fee")}</label>
            <span>
              {propsTradeFormDetail?.fee * 1 === 0
                ? "--"
                : propsTradeFormDetail?.arrival_currency}{" "}
              {propsTradeFormDetail?.arrival_currency === "BRL" ? (
                <span>
                  {propsTradeFormDetail?.fee * 1 === 0
                    ? ""
                    : (propsTradeFormDetail?.fee)
                        .toString()
                        .replace(".", ",") || "--"}
                </span>
              ) : (
                <span>
                  {propsTradeFormDetail?.fee * 1 === 0
                    ? ""
                    : propsTradeFormDetail?.fee || "--"}
                </span>
              )}
            </span>
          </div>
          <hr className="baseline" />
          <h3 className="trade-tip flex-box">
            <label>{t("total")}</label>
            <p>
              <span style={{ marginRight: "5px", fontWeight: "normal" }}>
                {propsTradeFormDetail.arrival_currency}
              </span>
              <span className="totalAccount">
                {propsTradeFormDetail.arrival_currency === "BRL" ? (
                  <span>
                    {propsTradeFormDetail.total.toString().replace(".", ",") ||
                      "--"}
                  </span>
                ) : (
                  <span>{propsTradeFormDetail.total || "--"}</span>
                )}
              </span>
            </p>
          </h3>
          {props.isHomePage && (
            <>
              <button
                className={classNames("payout-btn", {
                  disabled: isDiabled,
                })}
                onClick={handleFormConfirm}
              >
                {t("confirm")}
              </button>
              <div className="goback-box">
                <a href={propsOrderDetail.return_url}>{t("back_txt")}</a>
                <img src={GoBackImg} alt="" />
              </div>
              <div className="checkbox-wrap">
                <Checkbox
                  size="small"
                  checked={isChecked}
                  onChange={handleChangePrivacy}
                />
                <span className="privacy-box">
                  {t("pricacy1")}{" "}
                  <span className="privacy" onClick={handleShowPolicy}>
                    {t("pricacy2")}
                  </span>{" "}
                  {t("pricacy3")}
                </span>
              </div>
              {!isChecked && (
                <p className="privacy-policy-error">
                  {t("privacy_policy_error")}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeFormDetail: state.tradeFormDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPrivacyPolicy: (item) => dispatch(setPrivacyPolicy(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeForm);
