import React, { useState, useEffect } from "react";
import "./TimeRemaining.scss";
import { useTranslation } from "react-i18next";

function TimeRemaining({ time, image, detail = false, disable = () => {} }) {
  const { t } = useTranslation();
  const [remainTime, setRemainTime] = useState({
    hour: "",
    min: "",
    sec: "",
  });

  useEffect(() => {
    let timeValue = time * 1000;
    const interval = setInterval(() => {
      let remain = timeValue - Date.now();
      if (remain <= 0) {
        setRemainTime({ hour: "00", min: "00", sec: "00" });
        clearInterval(interval);
        disable();
      } else if (!isNaN(remain)) {
        let value = new Date(remain).toISOString().substr(11, 8);
        value = value.split(":");
        setRemainTime({ hour: value[0], min: value[1], sec: value[2] });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="time">
      <img src={image} alt="" />
      <div className="time__timeValueContainer">
        <p>
          <span className="time-remain">{t("time_remain")}:</span>
          <span className="time__timeValue">{`${remainTime.hour}h:${remainTime.min}m:${remainTime.sec}s`}</span>
        </p>
        {detail && (
          <p>
            <span>Please pay before:</span>
            <span className="time__timeValue">22 Jul 5:33 pm</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default TimeRemaining;
