import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions";

// component
import DropDownMenu from "../../components/dropDownMenu/DropDownMenu";
import { setSessionStorage } from "../../utils/config";

// plugin
import FlipMove from "react-flip-move";
import uuid from "react-uuid";

//  image
import pagsmileLogo from "../../images/pagsmile_logo.png";
import select from "../../images/select_icon.png";
import LangLogo from "../../images/地球.png";
import InfoLogo from "../../images/信息.png";
// css
import "./Header.scss";

function Header(props) {
  //console.log("Header-props", props);
  const { i18n, t } = useTranslation();
  const [dropDownState, setDropDpwnState] = useState({
    state: false,
    value: "main",
  });
  const lngText = t("lng_text");

  const handleLangChange = (event) => {
    props.setLanguage(event.target.id);
    setSessionStorage("lng", event.target.id);
    i18n.changeLanguage(event.target.id);
  };
  return (
    <>
      <div className="header-wrap">
        <div className="header">
          <div className="box-left">
            <img src={pagsmileLogo} alt="logo" />
          </div>
          <div className="box-right">
            {/* Language */}
            <img src={LangLogo} alt="" />
            <div className="header-lang-container">
              <span className="header-lang-text">{lngText}</span>
              <img src={select} alt="" />
              <div className="header-lang-dropdown">
                <span
                  className="header-lang-text"
                  onClick={handleLangChange}
                  id="en"
                >
                  English
                </span>
                <span
                  className="header-lang-text"
                  onClick={handleLangChange}
                  id="es"
                >
                  Español
                </span>
                <span
                  className="header-lang-text"
                  onClick={handleLangChange}
                  id="pt"
                >
                  Português
                </span>
              </div>
            </div>

            {/* Message */}
            <img
              className="header-info"
              src={InfoLogo}
              alt=""
              onClick={() => {
                setDropDpwnState((prev) => ({
                  state: true,
                  value: "main",
                }));
              }}
            />
          </div>
        </div>
        <FlipMove
          enterAnimation="accordionVertical"
          leaveAnimation="accordionVertical"
        >
          {dropDownState.state && (
            <DropDownMenu
              back={() => {
                setDropDpwnState((prev) => ({
                  ...prev,
                  state: false,
                }));
              }}
              state={dropDownState.value}
              key={uuid()}
            />
          )}
        </FlipMove>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (item) => dispatch(setLanguage(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
