import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { debounce } from "lodash";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { setTradeFormDetail } from "../../redux/actions";
import { PuffLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

import SelectedImg from "../../images/selected_green.png";
import CloseImg from "../../images/close_gray.png";
import WarningImg from "../../images/warning.png";

import Select from "react-select-search";
import "../../react-select-search.css";

// component
import FormInput from "../../package/form/FormInput";
import FormSelect from "../../package/form/FormSelect";
import TradeForm from "../../components/tradeForm/TradeForm";

import PrivacyPolicy from "./PrivacyPolicy";

// utils
import $axios from "../../utils/http";
import * as config from "./formConfig";
import {
  transfiyaBankConfig,
  methodConfig,
  getQueryVariable,
  notify,
  setSessionStorage,
  getSessionStorage,
  getPhoneAreaCode,
  walletLabelMap,
} from "../../utils/config";

import {
  speiDocumentTypeOptions,
  getPixKeyTypeOptions,
  getAccountTypeOptions,
  getSpeiAccountTypeOptions,
  transfiyaDocumentTypeOptions,
  getFeeTemplate,
  getArrivalCurrency,
} from "./utils";

// css
import "./HomePage.scss";

const classNames = require("classnames");

function HomePage(props) {
  //console.log("HomePage-props", props);
  const { t } = useTranslation();
  const token = getQueryVariable("t");
  const history = useHistory();

  const propsTradeInfo = props.tradeInfo;
  const countryOptions = propsTradeInfo.country_list;

  // get relation options
  const pixKeyTypeOptions = getPixKeyTypeOptions(t);
  const accountTypeOptions = getAccountTypeOptions(t);
  const speiAccountTypeOptions = getSpeiAccountTypeOptions(t);

  // state
  const [submitBtnFlag, setSubmitBtnFlag] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [method, setMethod] = useState("");
  const [paypalCountryOptions, setPaypalCountryOptions] = useState([]);
  const [showPolicy, setShowPolicy] = useState(false);
  const [noticeText, setNoticeText] = useState("");
  const [selectNotice, setSelectNotice] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [transfiyaBankOptions, setTransfiyaBankOptions] = useState([]);
  const [cacheTransfiyaBankList, setCacheTransfiyaBankList] = useState([]);
  const [transfiyaBankAccount, setTransfiyaBankAccount] = useState("");
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const handleChangeCountry = (val) => {
    if (val !== selectedCountry) {
      setTransfiyaBankAccount("");
      setMethod("");
      setStep2(false);
      props.setTradeFormDetail({});
    }
    setSelectedCountry(val);
    setStep1(true);
    propsTradeInfo.form_list.some((list) => {
      if (list.country === val) {
        const resMethod = list.method.map((item) => {
          if (item.name === "WALLET") {
            const name = walletLabelMap[val];
            return {
              name,
              value: item.value,
            };
          } else {
            return {
              name: item.name,
              value: item.value,
            };
          }
        });
        setMethodOptions(resMethod);
        return true;
      }
    });
  };
  const handleChangeMethod = (value) => {
    const temp = getFeeTemplate(selectedCountry, value);
    props.setTradeFormDetail(propsTradeInfo.fee_list[temp]);

    propsTradeInfo.form_list.some((list) => {
      if (list.country === selectedCountry) {
        list.method.some((item) => {
          if (item.name === value) {
            if (item.name === "TRANSFIYA") {
              const bankList = item.bank_list.map((item) => {
                item.bankAccount = "";
                return item;
              });
              setTransfiyaBankOptions(bankList);
              setCacheTransfiyaBankList(bankList);
            } else {
              setBankOptions(item.bank_list);
            }
            return true;
          }
        });
        return true;
      }
    });
    setMethod(value);
    setStep2(true);
  };
  // 获取global paypal的国家列表
  useEffect(() => {
    $axios({
      url: "/api/internal/checkout/paypal/countries",
      method: "POST",
    }).then((res) => {
      if (res.code === 200) {
        setPaypalCountryOptions(res.data);
      }
    });
  }, []);

  // 切换语言
  useEffect(() => {
    setMethod("");
    setStep2(false);
    setTransfiyaBankAccount("");
  }, [props.language]);

  const closePolicy = () => {
    setShowPolicy(false);
  };

  const handleFormSubmitValidate = (props) => {
    props.submitForm();
  };

  const fillRequestParams = (type, formValues) => {
    const arrivalCurrency = getArrivalCurrency(selectedCountry, method);
    // params
    let requestData = {
      country: selectedCountry,
      method: method,
    };
    if (type === "pix") {
      let documentType = "";
      if (formValues.pixCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      let account = "";
      if (formValues.pixKeyType === "PHONE") {
        account = getPhoneAreaCode(selectedCountry, formValues.pixKey);
      } else {
        account = formValues.pixKey;
      }
      requestData = {
        ...requestData,
        name: formValues.pixName,
        document_type: documentType,
        document_id: formValues.pixCpf_cnpj,
        account_type: formValues.pixKeyType,
        account: account,
        email: formValues.pixEmail,
        phone: formValues.pixCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "banktranfer") {
      let documentType = "";
      if (
        formValues.banktranferCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14
      ) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.banktranferName,
        document_type: documentType,
        document_id: formValues.banktranferCpf_cnpj,
        account_type: formValues.banktranferAccountType,
        account: formValues.banktranferAccountNumber,
        email: formValues.banktranferEmail,
        phone: formValues.banktranferCellphone,
        bank_code: formValues.banktranferBank,
        branch: formValues.banktranferBankBranch,
        branch_digit: formValues.banktranferBranchDigit,
        account_digit: formValues.banktranferAccountDigit,
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "wallet") {
      let documentType = "";
      if (formValues.walletCpf_cnpj.replace(/[^0-9]/gi, "").length !== 14) {
        documentType = "cpf";
      } else {
        documentType = "cnpj";
      }
      requestData = {
        ...requestData,
        name: formValues.walletName,
        document_type: documentType,
        document_id: formValues.walletCpf_cnpj,
        account_type: "EMAIL",
        account: formValues.walletAccount,
        email: formValues.walletEmail,
        phone: formValues.walletCellphone,
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "spei") {
      requestData = {
        ...requestData,
        name: formValues.speiName,
        document_type: formValues.speiDocumentType,
        document_id: formValues.speiDocumentId,
        account_type: formValues.speiAccountType,
        account: formValues.speiAccount,
        email: formValues.speiEmail,
        phone: formValues.speiCellphone,
        bank_code: formValues.speiBank,
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "global-paypal") {
      requestData = {
        ...requestData,
        country: formValues.paypalCountry,
        account_type: "EMAIL",
        account: formValues.paypalAccount,
        email: "",
        phone: "",
        bank_code: "",
        branch: "",
        branch_digit: "",
        account_digit: "",
        channel: "paypal",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "transfiya") {
      requestData = {
        ...requestData,
        name: formValues.name,
        account_type: "phone",
        account: formValues.phoneNumber,
        document_type: formValues.documentType,
        document_id: formValues.documentId,
        bank_number: transfiyaBankAccount,
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    } else if (type === "nequi") {
      requestData = {
        ...requestData,
        account_type: "phone",
        account: formValues.phoneNumber,
        channel: "nequi",
        arrival_currency: arrivalCurrency,
        custom_code: propsTradeInfo.order_detail.custom_code,
        additional_remark: propsTradeInfo.order_detail.description,
      };
    }
    return requestData;
  };

  const handleFormSubmit = (type, formValues) => {
    console.log("handleFormSubmit", type, formValues);

    const requestData = fillRequestParams(type, formValues);

    // if (
    //   type === "transfiya" &&
    //   transfiyaBankOptions.length > 0 &&
    //   !transfiyaBankAccount
    // ) {
    //   setNoticeText(t("home.formValidate.selectPaymentBank"));
    //   setSelectNotice(true);
    //   return false;
    // }

    // 协议是否勾选
    if (!props.privacyPolicy) {
      return false;
    }

    if (submitBtnFlag) {
      return false;
    }
    setIsloading(true);
    setSubmitBtnFlag(true);
    $axios({
      url: "/api/internal/checkout/commit",
      method: "POST",
      data: requestData,
      headers: {
        "Accept-Language": getSessionStorage("lng") || "pt",
      },
    })
      .then((res) => {
        if (res.code === 200) {
          notify("success", res.msg, 2000, () => {
            setSessionStorage("_t", res.data.token);
            setSessionStorage("_od", propsTradeInfo.order_detail);
            setSessionStorage("_td", props.tradeFormDetail);
            history.push("/result?t=" + token);
          });
        } else {
          setSubmitBtnFlag(false);
        }
      })
      .catch((err) => {
        setSubmitBtnFlag(false);
        setIsloading(false);
        notify("error", err.msg);
      });
  };

  const handleChooseTransfiyaBank = (e) => {
    const targetElement = e.currentTarget;
    const paymentImgs = document.querySelectorAll(".bank-payment-img");
    if (targetElement.classList.contains("supported")) {
      for (let j = 0; j < paymentImgs.length; j++) {
        paymentImgs[j].setAttribute(
          "class",
          paymentImgs[j].getAttribute("class").replace("active", "")
        );
      }
      const bankAcccount =
        targetElement.parentElement.lastElementChild.innerHTML;
      targetElement.classList.add("active");
      setTransfiyaBankAccount(bankAcccount);
    }
  };

  const processedTransfiyaBankData = (supportedBankData) => {
    let count = 0;
    const _transfiyaBankList = JSON.parse(
      JSON.stringify(cacheTransfiyaBankList)
    );
    const processedBankData = _transfiyaBankList
      .map((temp) => {
        temp.id = 999;
        return temp;
      })
      .map((item) => {
        supportedBankData.forEach((per) => {
          if (item.value == per.bank_code) {
            item.bankAccount = per.bank_number;
            item.id = count++;
          }
        });
        return item;
      })
      .sort((a, b) => a.id - b.id)
      .filter((data) => data.id != 999);
    return processedBankData;
  };

  const getSupportedTransfiyaBankList = debounce((phoneNumber) => {
    setLoadingOverlay(true);
    $axios({
      url: "/api/internal/checkout/account_list",
      method: "POST",
      data: {
        account_type: "PHONE",
        account: phoneNumber,
      },
      headers: {
        "Accept-Language": getSessionStorage("lng") || "pt",
      },
    })
      .then((res) => {
        console.group("qqq", res);
        if (res.code === 200) {
          const resData = res.data;
          if (!resData) {
            setTransfiyaBankOptions(cacheTransfiyaBankList);
            setTransfiyaBankAccount("");
            setTimeout(() => {
              resetColBankList();
            }, 1000);
            return false;
          }
          if (resData?.length < 1) {
            setTransfiyaBankOptions(cacheTransfiyaBankList);
            setTransfiyaBankAccount("");
            setTimeout(() => {
              resetColBankList();
            }, 1000);
            return false;
          }

          const bankData = processedTransfiyaBankData(resData);
          setTransfiyaBankOptions(bankData);
        }
      })
      .catch((err) => {
        notify("error", err.msg);
      })
      .finally(() => {
        setLoadingOverlay(false);
      });
  }, 1000);

  const resetColBankList = () => {
    const paymentImgs = document.querySelectorAll(".bank-payment-img");
    if (paymentImgs.length < 1) {
      setTimeout(() => {
        resetColBankList();
      }, 1000);
      return false;
    }
    for (let j = 0; j < paymentImgs.length; j++) {
      paymentImgs[j].setAttribute(
        "class",
        paymentImgs[j].getAttribute("class").replace("active", "")
      );
    }
  };

  const handleChangePhoneNumber = (e) => {
    const phoneNumber = e.currentTarget.value;
    if (/^(\+57|57)?\d{12,14}$/.test(phoneNumber)) {
      getSupportedTransfiyaBankList(phoneNumber);
    } else {
      setTransfiyaBankAccount("");
      console.log("hhhhhhhh", cacheTransfiyaBankList);
      setTransfiyaBankOptions(cacheTransfiyaBankList);
      setTimeout(() => {
        resetColBankList();
      }, 1000);
    }
  };

  const submitFormBtnPortal = (cb) => {
    return ReactDOM.createPortal(
      <button type="button" className="submit-form" onClick={cb}>
        submit
      </button>,
      document.querySelector(".payout-form")
    );
  };

  return (
    <>
      {showPolicy && <PrivacyPolicy back={closePolicy} />}
      <div
        className="home__loader"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <div className="loader">
          <PuffLoader color="#01b88f" />
        </div>
      </div>
      <div
        className="home__notice"
        style={{ display: selectNotice ? "block" : "none" }}
      >
        <div className="home__notice__warning">
          <img
            className="home__notice__warningClose"
            src={CloseImg}
            alt=""
            onClick={() => {
              setSelectNotice(false);
            }}
          />
          <img src={WarningImg} alt="" />
          <p className="home__notice__warningText">{noticeText}</p>
          <button
            className="home__notice__warningButton"
            onClick={() => {
              setSelectNotice(false);
            }}
          >
            {t("okay")}
          </button>
        </div>
      </div>
      <div className="home-wrap">
        <div className="home-box">
          <div className="box-left">
            <div className="step1">
              <span className="step-circle">1</span>
              <div
                className="step-next"
                style={{ borderLeftColor: step1 ? "#c1bfbf" : "transparent" }}
              >
                <h2 className="title">{t("home_step1")}</h2>
                <Select
                  options={countryOptions}
                  value={selectedCountry}
                  autoComplete="off"
                  onChange={handleChangeCountry}
                />
              </div>
            </div>
            <div
              className="step2"
              style={{ display: step1 ? "block" : "none" }}
            >
              <span className="step-circle">2</span>
              <div
                className="step-next"
                style={{ borderLeftColor: step2 ? "#c1bfbf" : "transparent" }}
              >
                <h2 className="title">{t("home_step2")}</h2>
                <div className="method-box">
                  {methodOptions.map((item) => (
                    <div
                      className="method"
                      key={item.value}
                      onClick={() => {
                        handleChangeMethod(item.value);
                      }}
                    >
                      <img
                        className={classNames("method-logo", {
                          "method-filter": item.value !== method,
                        })}
                        src={methodConfig(selectedCountry)[item.value]}
                        alt=""
                      />
                      {item.value === method && (
                        <img
                          className="method-selected-img"
                          src={SelectedImg}
                          alt=""
                        />
                      )}
                      <p className="method-text">{item.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="step3"
              style={{ display: step2 ? "block" : "none" }}
            >
              <span className="step-circle">3</span>
              <div className="step-next" style={{ borderLeft: "none" }}>
                <h2 className="title">{t("home_step3_t1")}</h2>
                <h3 className="title-fu">{t("home_step3_t2")}</h3>
                {selectedCountry === "BRA" && method === "BANKTRANSFER" && (
                  <Formik
                    initialValues={config.banktranferInitialValues}
                    validationSchema={config.banktranferValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      handleFormSubmit("banktranfer", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).name}
                              name="banktranferName"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).cpf_cnpj}
                              name="banktranferCpf_cnpj"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.banktranferLabelMap(t).bank}
                              name="banktranferBank"
                              required="required"
                              options={bankOptions}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              value={props.values.banktranferBank}
                              error={props.errors.banktranferBank}
                              touched={props.touched.banktranferBank}
                            />
                            <FormSelect
                              label={config.banktranferLabelMap(t).account_type}
                              name="banktranferAccountType"
                              required="required"
                              options={accountTypeOptions}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              value={props.values.banktranferAccountType}
                              error={props.errors.banktranferAccountType}
                              touched={props.touched.banktranferAccountType}
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={
                                config.banktranferLabelMap(t).account_number
                              }
                              name="banktranferAccountNumber"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={
                                config.banktranferLabelMap(t).account_digit
                              }
                              name="banktranferAccountDigit"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).bank_branch}
                              name="banktranferBankBranch"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).branch_digit}
                              name="banktranferBranchDigit"
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.banktranferLabelMap(t).email}
                              name="banktranferEmail"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.banktranferLabelMap(t).cellphone}
                              name="banktranferCellphone"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "BRA" && method === "PIX" && (
                  <Formik
                    initialValues={config.pixInitialValues}
                    validationSchema={config.pixValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      handleFormSubmit("pix", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.pixLabelMap(t).name}
                              name="pixName"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.pixLabelMap(t).cpf_cnpj}
                              name="pixCpf_cnpj"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.pixLabelMap(t).key_type}
                              name="pixKeyType"
                              required="required"
                              options={pixKeyTypeOptions}
                              value={props.values.pixKeyType}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.pixKeyType}
                              touched={props.touched.pixKeyType}
                            />
                            <FormInput
                              label={config.pixLabelMap(t).pix_key}
                              name="pixKey"
                              required="required"
                              id={props.values.pixKeyType}
                              type="text"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.pixLabelMap(t).email}
                              name="pixEmail"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.pixLabelMap(t).cellphone}
                              name="pixCellphone"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {(selectedCountry === "BRA" || selectedCountry === "MEX") &&
                  method === "WALLET" && (
                    <Formik
                      initialValues={config.walletInitialValues}
                      validationSchema={config.walletValidationSchema(
                        selectedCountry,
                        t
                      )}
                      onSubmit={async (values, { setSubmitting }) => {
                        handleFormSubmit("wallet", values);
                      }}
                    >
                      {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                          <>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).name}
                                name="walletName"
                                type="text"
                                required="required"
                              />
                              <FormInput
                                label={config.walletLabelMap(t).cpf_cnpj}
                                name="walletCpf_cnpj"
                                type="text"
                                required="required"
                              />
                            </div>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).account}
                                name="walletAccount"
                                type="text"
                                required="required"
                              />
                              <FormInput
                                label={config.walletLabelMap(t).email}
                                name="walletEmail"
                                type="text"
                                required="required"
                              />
                            </div>
                            <div className="form-item">
                              <FormInput
                                label={config.walletLabelMap(t).cellphone}
                                name="walletCellphone"
                                type="text"
                                required="required"
                              />
                            </div>
                            {submitFormBtnPortal(() =>
                              handleFormSubmitValidate(props)
                            )}
                          </>
                        </Form>
                      )}
                    </Formik>
                  )}
                {selectedCountry === "MEX" && method === "SPEI" && (
                  <Formik
                    initialValues={config.speiInitialValues}
                    validationSchema={config.speiValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      //console.log("spei",values)
                      handleFormSubmit("spei", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.speiLabelMap(t).name}
                              name="speiName"
                              type="text"
                              required="required"
                            />
                            <FormSelect
                              label={config.speiLabelMap(t).bank}
                              name="speiBank"
                              required="required"
                              options={bankOptions}
                              value={props.values.speiBank}
                              searchable={true}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiBank}
                              touched={props.touched.speiBank}
                              aa={props}
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.speiLabelMap(t).document_type}
                              name="speiDocumentType"
                              required="required"
                              options={speiDocumentTypeOptions}
                              value={props.values.speiDocumentType}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiDocumentType}
                              touched={props.touched.speiDocumentType}
                            />
                            <FormInput
                              label={config.speiLabelMap(t).document_id}
                              name="speiDocumentId"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={config.speiLabelMap(t).account_type}
                              name="speiAccountType"
                              required="required"
                              options={speiAccountTypeOptions}
                              value={props.values.speiAccountType}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.speiAccountType}
                              touched={props.touched.speiAccountType}
                            />
                            <FormInput
                              label={config.speiLabelMap(t).account}
                              name="speiAccount"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.speiLabelMap(t).email}
                              name="speiEmail"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.speiLabelMap(t).cellphone}
                              name="speiCellphone"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "GLOBAL" && method === "WALLET" && (
                  <Formik
                    initialValues={config.paypalInitialValues}
                    validationSchema={config.paypalValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      //console.log("global-paypal", values)
                      handleFormSubmit("global-paypal", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormSelect
                              label={config.globalPaypalLabelMap(t).country}
                              name="paypalCountry"
                              required="required"
                              options={paypalCountryOptions}
                              value={props.values.paypalCountry}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.paypalCountry}
                              touched={props.touched.paypalCountry}
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={config.globalPaypalLabelMap(t).account}
                              name="paypalAccount"
                              type="text"
                              required="required"
                            />
                          </div>
                          <div className="form-item">
                            <FormInput
                              label={
                                config.globalPaypalLabelMap(t).confirmAccount
                              }
                              name="paypalConfirmAccount"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "COL" && method === "TRANSFIYA" && (
                  <Formik
                    initialValues={config.transfiyaInitialValues}
                    validationSchema={config.transfiyaValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      //console.log("global-paypal", values)
                      handleFormSubmit("transfiya", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.colTransfiyaLabelMap(t).name}
                              name="name"
                              type="text"
                              required="required"
                            />
                            <FormInput
                              label={config.colTransfiyaLabelMap(t).phoneNumber}
                              name="phoneNumber"
                              type="text"
                              required="required"
                              onInput={handleChangePhoneNumber}
                            />
                          </div>
                          <div>
                            <p className="choose-bank">
                              {t("home.formValidate.selectPaymentBank")}:
                            </p>
                            <LoadingOverlay active={loadingOverlay} spinner>
                              <div className="bank-payment-img-box">
                                {transfiyaBankOptions.map((item) => (
                                  <div
                                    className="bank-payment-img-item"
                                    key={item.value}
                                  >
                                    <div
                                      className={classNames(
                                        "bank-payment-img",
                                        {
                                          supported: !!item.bankAccount,
                                        }
                                      )}
                                      onClick={handleChooseTransfiyaBank}
                                    >
                                      <img
                                        className={classNames({
                                          supported: !!item.bankAccount,
                                        })}
                                        src={transfiyaBankConfig()[item.value]}
                                      />
                                    </div>
                                    <p
                                      className={classNames("bank-name", {
                                        supported: !!item.bankAccount,
                                      })}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      className={classNames("bank-account", {
                                        supported: !!item.bankAccount,
                                      })}
                                    >
                                      {item.bankAccount}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </LoadingOverlay>
                          </div>
                          <div className="form-item">
                            <FormSelect
                              label={
                                config.colTransfiyaLabelMap(t).documentType
                              }
                              name="documentType"
                              required="required"
                              options={transfiyaDocumentTypeOptions}
                              value={props.values.documentType}
                              onChange={props.setFieldValue}
                              onBlur={props.setFieldTouched}
                              error={props.errors.documentType}
                              touched={props.touched.documentType}
                            />
                            <FormInput
                              label={config.colTransfiyaLabelMap(t).documentId}
                              name="documentId"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedCountry === "COL" && method === "WALLET" && (
                  <Formik
                    initialValues={config.nequiInitialValues}
                    validationSchema={config.nequiValidationSchema(
                      selectedCountry,
                      t
                    )}
                    onSubmit={async (values, { setSubmitting }) => {
                      handleFormSubmit("nequi", values);
                    }}
                  >
                    {(props) => (
                      <Form onSubmit={props.handleSubmit}>
                        <>
                          <div className="form-item">
                            <FormInput
                              label={config.colNequiLabelMap(t).phoneNumber}
                              name="phoneNumber"
                              type="text"
                              required="required"
                            />
                          </div>
                          {submitFormBtnPortal(() =>
                            handleFormSubmitValidate(props)
                          )}
                        </>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
          <div className="box-right">
            <TradeForm
              handleSetShowPolicy={setShowPolicy}
              isHomePage={true}
              step1={step1}
              step2={step2}
              setSelectNotice={setSelectNotice}
              setNoticeText={setNoticeText}
            ></TradeForm>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    tradeFormDetail: state.tradeFormDetail,
    language: state.language,
    privacyPolicy: state.privacyPolicy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeFormDetail: (item) => dispatch(setTradeFormDetail(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
