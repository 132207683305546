import React, {Component} from "react";
import notfindImg from "../images/404.png";

export default class _404Page extends Component {
  render() {
    return (
      <div>
        <img src={notfindImg} alt="" style={{position:"absolute",top: 0, bottom: 0, left: 0, right: 0, margin: "auto"}} />
      </div>
    );
  }
}
