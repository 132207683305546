import React from "react";
import { useTranslation } from "react-i18next";
import { getSessionStorage } from "../../utils/config";
import ErrorIcon from "../../images/error.png";
import QuestionIcon from "../../images/问号.png";
import HelperIcon from "../../images/客服.png";

import "./ResultFail.scss";

function ResultFail(props) {
  const { t } = useTranslation();
  const backUrl = getSessionStorage("_od")?.return_url;
  const handleTry = () => {
    window.location.href = backUrl;
  };
  return (
    <div className="result-fail-wrap">
      <div className="status-box">
        <div className="question-box">
          <img src={ErrorIcon} className="icon" alt="" />
          <img src={QuestionIcon} className="question-icon" alt="" />
          <div className="question-tip">
            <img src={HelperIcon} className="helper-img" alt="" />
            <span>{t("live_chat")}</span>
            <p className="txt-tip">
              {t("live_chat_tip1")}
            </p>
            <p className="txt-tip">
              {t("live_chat_tip2")}
            </p>
          </div>
        </div>
        <p className="result-text">{t("transaction_failed")}</p>
        <div className="tip-box">
          <p className="tip1">
            {t("failed_label")} <span>{props.errorDescription}</span>
          </p>
          <div className="btn-box">
            <button className="btn try" onClick={handleTry}>
              {t("try_again")}
            </button>
            <button
              className="btn help"
              onClick={() => {
                window?.LC_API?.open_chat_window();
              }}
            >
              {t("help")}
            </button>
          </div>
          <p className="tip2">
            {t("result_tip2")}{" "}
            <a href="https://www.pagsmile.com/">https://www.pagsmile.com/</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResultFail;
