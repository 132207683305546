import { cpf, cnpj } from "cpf-cnpj-validator";
import { toast } from "react-toastify";

import Spei from "../images/spei.png";
import Pix from "../images/pix.png";
import Banktranfer from "../images/banktranfer.png";
import Paypal from "../images/wallet.png";
import Nequi from "../images/nequi.png";
import Transfiya from "../images/transfiya.png";
import Zuoshaowei from "../images/merchant/weige.jpg";

// transfiya
import Bank1001 from "../images/transfiya/1001.png";
import Bank1006 from "../images/transfiya/1006.png";
import Bank1007 from "../images/transfiya/1007.png";
import Bank1013 from "../images/transfiya/1013.png";
import Bank1019 from "../images/transfiya/1019.png";
import Bank1023 from "../images/transfiya/1023.png";
import Bank1032 from "../images/transfiya/1032.png";
import Bank1040 from "../images/transfiya/1040.png";
import Bank1051 from "../images/transfiya/1051.png";
import Bank1052 from "../images/transfiya/1052.png";
import Bank1059 from "../images/transfiya/1059.png";
import Bank1062 from "../images/transfiya/1062.png";
import Bank1063 from "../images/transfiya/1063.png";
import Bank1066 from "../images/transfiya/1066.png";
import Bank1069 from "../images/transfiya/1069.png";
import Bank1097 from "../images/transfiya/1097.png";
import Bank1292 from "../images/transfiya/1292.png";
import Bank1507 from "../images/transfiya/1507.png";
import Bank1551 from "../images/transfiya/1551.png";
import Bank1566 from "../images/transfiya/1566.png";
import Bank1801 from "../images/transfiya/1801.png";

const _format = (val) => {
  const len = val.replace(/[^0-9]/gi, "").length;
  if (len !== 14) {
    // cpf
    if (cpf.isValid(val)) {
      return cpf.format(val);
    } else {
      return val.replace(/[^0-9]/gi, "");
    }
  } else {
    // cnpj
    if (cnpj.isValid(val)) {
      return cnpj.format(val);
    } else {
      return val.replace(/[^0-9]/gi, "");
    }
  }
};

const _formatCpf = (val) => {
  if (cpf.isValid(val)) {
    return cpf.format(val);
  } else {
    return val.replace(/[^0-9]/gi, "");
  }
};

const _formatCnpj = (val) => {
  if (cnpj.isValid(val)) {
    return cnpj.format(val);
  } else {
    return val.replace(/[^0-9]/gi, "");
  }
};

const walletImageMap = {
  GLOBAL: Paypal,
  COL: Nequi,
};

export const walletLabelMap = {
  GLOBAL: "PAYPAL",
  COL: "NEQUI",
};

const methodConfig = (country) => {
  return {
    SPEI: Spei,
    PIX: Pix,
    BANKTRANSFER: Banktranfer,
    WALLET: walletImageMap[country],
    TRANSFIYA: Transfiya,
  };
};

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const notify = (type = "error", text, duration = 3000, cb) => {
  const _o = {
    position: "top-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
  };
  if (type === "error") {
    toast.error(text, _o);
  } else if (type === "success") {
    toast.success(text, _o);
  }
  if (cb) {
    setTimeout(() => {
      cb();
    }, duration);
  }
};

const setSessionStorage = (key, val) => {
  if (typeof val === "object") {
    sessionStorage.setItem(key, JSON.stringify(val));
  } else {
    sessionStorage.setItem(key, val);
  }
};

const getSessionStorage = (key) => {
  if (
    sessionStorage.getItem(key)?.indexOf("{") >= 0 ||
    sessionStorage.getItem(key)?.indexOf("}") >= 0 ||
    sessionStorage.getItem(key)?.indexOf("[") >= 0 ||
    sessionStorage.getItem(key)?.indexOf("]") >= 0
  ) {
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    return sessionStorage.getItem(key);
  }
};

const titleCase = (str) => {
  return str.toLowerCase().replace(/\b([\w|']+)\b/g, function (word) {
    return word.replace(word.charAt(0), word.charAt(0).toUpperCase());
  });
};

const phoneAreaCodeMap = {
  BRA: {
    areaCode: "55",
    reg: /^(\+55|55)?\d{11}$/,
  },
  MEX: {
    areaCode: "52",
    reg: /^(\+52|52)?\d{6,10}$/,
  },
  COL: {
    areaCode: "57",
    reg: /^(\+57|57)?\d{12,14}$/,
  },
};

const getPhoneAreaCode = (country, phoneVal) => {
  if (phoneVal.length === 11) {
    return "+" + phoneAreaCodeMap[country]["areaCode"] + phoneVal;
  } else if (phoneVal.length === 13) {
    return "+" + phoneVal;
  } else if (phoneVal.length === 14) {
    return phoneVal;
  }
};

const merchantImgMap = {
  32: Zuoshaowei,
};

const transfiyaBankConfig = () => {
  return {
    1001: Bank1001,
    1006: Bank1006,
    1007: Bank1007,
    1013: Bank1013,
    1019: Bank1019,
    1023: Bank1023,
    1032: Bank1032,
    1040: Bank1040,
    1051: Bank1051,
    1052: Bank1052,
    1059: Bank1059,
    1062: Bank1062,
    1063: Bank1063,
    1066: Bank1066,
    1069: Bank1069,
    1097: Bank1097,
    1292: Bank1292,
    1507: Bank1507,
    1551: Bank1551,
    1566: Bank1566,
    1801: Bank1801,
  };
};

export {
  transfiyaBankConfig,
  methodConfig,
  _format,
  _formatCpf,
  _formatCnpj,
  getQueryVariable,
  notify,
  setSessionStorage,
  getSessionStorage,
  titleCase,
  getPhoneAreaCode,
  phoneAreaCodeMap,
  merchantImgMap,
};
