import {
  SET_TRADE_INFO,
  SET_TRADE_FORM_DETAIL,
  SET_LANGUAGE,
  SET_PRIVACY_POLICY,
} from "./types";

const initialState = {
  tradeInfo: {},
  tradeFormDetail: {},
  language: "",
  privacyPolicy: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRADE_INFO:
      return {
        ...state,
        tradeInfo: action.item,
      };
    case SET_TRADE_FORM_DETAIL:
      return {
        ...state,
        tradeFormDetail: action.item,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.item,
      };
    case SET_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicy: action.item,
      };
    default:
      return state;
  }
};

export default reducer;
