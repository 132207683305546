const speiDocumentTypeOptions = [
  { name: "RFC", value: "RFC" },
  { name: "CURP", value: "CURP" },
];

const getPixKeyTypeOptions = (t) => {
  return [
    { name: "CPF", value: "CPF" },
    { name: "CNPJ", value: "CNPJ" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: t("home.formSections.email"), value: "EMAIL" },
    { name: "EVP", value: "EVP" },
  ];
};

const getAccountTypeOptions = (t) => {
  return [
    { name: t("home.formSections.checking"), value: "CHECKING" },
    { name: t("home.formSections.savings"), value: "SAVINGS" },
  ];
};

const getSpeiAccountTypeOptions = (t) => {
  return [
    { name: t("home.formSections.debit"), value: "DEBIT" },
    { name: t("home.formSections.phone"), value: "PHONE" },
    { name: "CLABE", value: "CLABE" },
  ];
};

const transfiyaDocumentTypeOptions = [
  { name: "CC", value: "CC" },
  { name: "CE", value: "CE" },
  { name: "PEP", value: "PEP" },
];

const getFeeTemplate = (country, method) => {
  let temp = `${country}-${method}`;
  if (country === "BRA") {
    if (method === "WALLET") {
      temp = `${country}-${method}-USD`;
    } else {
      temp = `${country}-${method}-BRL`;
    }
  } else if (country === "MEX") {
    if (method === "WALLET") {
      temp = `${country}-${method}-USD`;
    } else {
      temp = `${country}-${method}-MXN`;
    }
  } else if (country === "GLOBAL") {
    temp = `${country}-${method}-USD`;
  } else if (country === "COL") {
    temp = `${country}-${method}-COP`;
  }
  return temp;
};

const getArrivalCurrency = (country, method) => {
  let arrivalCurrency = "";
  if (country === "BRA") {
    if (method === "WALLET") {
      arrivalCurrency = "USD";
    } else {
      arrivalCurrency = "BRL";
    }
  } else if (country === "MEX") {
    if (method === "WALLET") {
      arrivalCurrency = "USD";
    } else {
      arrivalCurrency = "MXN";
    }
  } else if (country === "GLOBAL") {
    arrivalCurrency = "USD";
  } else if (country === "COL") {
    arrivalCurrency = "COP";
  }
  return arrivalCurrency;
};

export {
  speiDocumentTypeOptions,
  getPixKeyTypeOptions,
  getAccountTypeOptions,
  getSpeiAccountTypeOptions,
  transfiyaDocumentTypeOptions,
  getFeeTemplate,
  getArrivalCurrency,
};
