import {
  SET_TRADE_INFO,
  SET_TRADE_FORM_DETAIL,
  SET_LANGUAGE,
  SET_PRIVACY_POLICY
} from "./types";


export const setTradeInfo = (item) => {
  return {
    type: SET_TRADE_INFO,
    item: item,
  };
};

export const setTradeFormDetail = (item) => {
  return {
    type: SET_TRADE_FORM_DETAIL,
    item: item,
  };
};

export const setLanguage = (item) => {
  return {
    type: SET_LANGUAGE,
    item: item,
  };
};

export const setPrivacyPolicy = (item) => {
  return {
    type: SET_PRIVACY_POLICY,
    item: item,
  };
};

