import axios from "axios";
import { url } from "./env";
import { getSessionStorage } from "./config";

axios.defaults.headers.common["Accept-Language"] = getSessionStorage("lng") || "pt"

const instance = axios.create({
  baseURL: url,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    if (getSessionStorage("_t")) {
      config.headers.Authorization = getSessionStorage("_t") ||"debug";
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response.data;
    }
  },
  function (error) {
    // if (error.response.status === 401 && error.response.data.code === 4004001) {
    //   const errorMsg = error.response.data.msg || "System Error";
    //   notify("error", errorMsg);
    // } 
    return Promise.reject(error.response.data);
    
  }
);

export default instance;
